import { useSuspenseQuery } from '@tanstack/react-query';
import type { JSX } from 'react';
import ReactDateTimePicker from 'react-datetime-picker';
import type { Callback } from 'ts/base/Callback';
import { useTimePickerContext } from 'ts/commons/time/components/TimePickerContext';
import { useInputWithDefault } from 'ts/commons/time/components/TimePickerUtils';
import useDefaultCalendarProps from 'ts/commons/time/DefaultCalendarPropsHook';
import { EPointInTimeType } from 'ts/commons/time/EPointInTimeType';
import { TimeContext } from 'ts/commons/time/TimeContext';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';

function validateAndExtractPointInTime(
	timestamp: number | null,
	setTypedPointInTime: Callback<TypedPointInTime>
): Promise<string | undefined> {
	if (timestamp == null) {
		return Promise.resolve('No date was selected.');
	}
	setTypedPointInTime(TimeUtils.timestamp(timestamp));
	return Promise.resolve(undefined);
}

function useSetInitialValueWithClient() {
	const { defaultValue } = useTimePickerContext();
	const { data: selectedTimestampFromDefinedPointInTime } = useSuspenseQuery({
		queryKey: ['selectedTimestampFromDefinedPointInTime', defaultValue],
		queryFn: () => new TimeContext().resolveToTimestamp(defaultValue)
	});
	return (defaultValue: TypedPointInTime | null) =>
		setInitialValue(defaultValue, selectedTimestampFromDefinedPointInTime);
}

function setInitialValue(
	defaultValue: TypedPointInTime | null,
	defaultTimestampFromDefinedPointInTime: number | null
): number {
	if (defaultTimestampFromDefinedPointInTime != null) {
		return defaultTimestampFromDefinedPointInTime;
	}
	if (defaultValue != null) {
		if (defaultValue.type === EPointInTimeType.TIMESTAMP || defaultValue.type === EPointInTimeType.REVISION) {
			return defaultValue.value.timestamp;
		}
	}
	return Date.now();
}

/** Props for DateTimePicker. */
type DateTimePickerProps = {
	dateOnly: boolean;
};

/** A component for picking a date and optional time of the day. */
export default function DateTimePicker({ dateOnly }: DateTimePickerProps): JSX.Element | null {
	const setInitialValue = useSetInitialValueWithClient();
	const [selectedValueAsTimestamp, setSelectedValueAsTimestamp] = useInputWithDefault(
		'date',
		validateAndExtractPointInTime,
		setInitialValue
	);
	const selectedValueAsDate = new Date(selectedValueAsTimestamp ?? Date.now());
	const calendarProps = useDefaultCalendarProps(dateOnly);
	return (
		<ReactDateTimePicker
			value={selectedValueAsDate}
			onChange={date => {
				if (date != null) {
					setSelectedValueAsTimestamp(date.getTime());
				}
			}}
			{...calendarProps}
		/>
	);
}
