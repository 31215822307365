import type { SanitizedHtml } from 'ts-closure-library/lib/soy/data';
import type { ColorCountObject } from 'ts/commons/formatter/TestGapAssessmentFormatter';
import { TestGapAssessmentFormatter } from 'ts/commons/formatter/TestGapAssessmentFormatter';
import type { ExtendedAssessmentDelta } from 'ts/data/ExtendedAssessmentDelta';
import type { ExtendedMetricDeltaValue } from 'ts/data/ExtendedMetricDeltaValue';
import type { AssessmentDelta } from 'typedefs/AssessmentDelta';
import type { EAggregationEntry } from 'typedefs/EAggregation';
import { EAggregation } from 'typedefs/EAggregation';
import { EMetricProperty } from 'typedefs/EMetricProperty';
import { EMetricValueType } from 'typedefs/EMetricValueType';
import { ETrafficLightColor } from 'typedefs/ETrafficLightColor';
import type { MetricAssessment } from 'typedefs/MetricAssessment';
import type { MetricDeltaValue } from 'typedefs/MetricDeltaValue';
import type { MetricDirectorySchema } from 'typedefs/MetricDirectorySchema';
import type { MetricDirectorySchemaEntry } from 'typedefs/MetricDirectorySchemaEntry';
import type { TgaValueEntry } from 'typedefs/TgaValueEntry';
import { EMetricName } from './EMetricName';
import { MetricFormatterBase } from './formatter/MetricFormatterBase';
import { MetricFormatterFactory } from './formatter/MetricFormatterFactory';
import { NumericValueFormatter } from './formatter/NumericValueFormatter';
import { TimestampMetricFormatter } from './formatter/TimestampMetricFormatter';
import { MarkdownUtils } from './markdown/MarkdownUtils';
import { MetricProperties } from './MetricProperties';
import { NavigationHash } from './NavigationHash';

/** Wrapper for types that are similar to a (metric) schema. */
type SchemaLikeType =
	| MetricDirectorySchemaEntry
	| MetricAssessment
	| (TgaValueEntry &
			// The "properties" attribute does not exist in the TgaValueEntry type
			// To still be able to reference it, it has to be included as optional
			Partial<Pick<MetricDirectorySchemaEntry, 'properties'>>);

/** Utility functions related to metrics. */
export class MetricsUtils {
	/** The string indicating that there was no change in a metrics delta. */
	public static readonly NO_CHANGE = '± 0';

	/** The string used for metrics that are not available in lowercase. */
	public static readonly NOT_AVAILABLE_STRING_LOWERCASE = 'n/a';

	/** The default threshold configuration name if no other was explicitly selected. */
	public static readonly TEAMSCALE_THRESHOLD_CONFIGURATION = 'Teamscale Default';

	/** The name of the 'Duration' metric. */
	public static readonly DURATION = 'Duration';

	/**
	 * Formats a metric value as HTML.
	 *
	 * @param value The metric value.
	 * @param options Further options to override the default options.
	 */
	public static formatMetricAsHtml(
		value: unknown,
		schemaEntryOrMetricAssessment: SchemaLikeType,
		options?: Record<string, boolean | number | string>,
		colorBlindModeEnabled?: boolean
	): SanitizedHtml | null {
		const formatterOptions = {
			// @ts-ignore
			rating: schemaEntryOrMetricAssessment.rating,
			[MetricFormatterBase.IS_RATIO_OPTION]: MetricProperties.isRatioMetric(schemaEntryOrMetricAssessment),
			[MetricFormatterBase.IS_DURATION_OPTION]: schemaEntryOrMetricAssessment.name === MetricsUtils.DURATION,
			[MetricFormatterBase.IS_EMPTY_ASSESSMENT_NEUTRAL_OPTION]:
				schemaEntryOrMetricAssessment.properties?.includes(EMetricProperty.EMPTY_ASSESSMENT_IS_NEUTRAL.name)
		};
		if (options != null) {
			Object.assign(formatterOptions, options);
		}
		const valueType = MetricsUtils.getSchemaEntryOrMetricAssessmentValueType(schemaEntryOrMetricAssessment);
		if (
			'metricThresholds' in schemaEntryOrMetricAssessment &&
			schemaEntryOrMetricAssessment.metricThresholds.thresholdRed != null
		) {
			const thresholds = schemaEntryOrMetricAssessment.metricThresholds;
			const thresholdTooltip = `Thresholds: [${ETrafficLightColor.RED.assessmentDisplayName}: ${
				thresholds.thresholdRed! * 100
			}%, ${ETrafficLightColor.YELLOW.assessmentDisplayName}: ${thresholds.thresholdYellow! * 100}%]`;
			Object.assign(formatterOptions, { [MetricFormatterBase.ADDITIONAL_TOOLTIP_TEXT_OPTION]: thresholdTooltip });
		}
		const formatter = MetricFormatterFactory.createFormatterForMetric(valueType, formatterOptions);
		Object.assign(formatterOptions, {
			// @ts-ignore
			[MetricFormatterBase.TOOLTIP_OVERRIDE_OPTION]: schemaEntryOrMetricAssessment.formattedTextValue
		});
		if (formatter != null && formatter instanceof TestGapAssessmentFormatter) {
			switch (schemaEntryOrMetricAssessment.name) {
				case 'Test Gap':
					return formatter.formatTestGapBar(value as ColorCountObject, colorBlindModeEnabled);
				case 'Execution':
					return formatter.formatExecutionBar(value as ColorCountObject, colorBlindModeEnabled);
				case 'Churn':
					return formatter.formatChurnBar(value as ColorCountObject, colorBlindModeEnabled);
				default:
					throw new Error('Unexpected metric assessment found: ' + schemaEntryOrMetricAssessment.name);
			}
		}
		if (formatter != null && formatter instanceof TimestampMetricFormatter) {
			return formatter.formatValueAsHtml(parseInt(value as string));
		}
		if (formatter != null) {
			return formatter.formatValueAsHtml(value, colorBlindModeEnabled);
		}
		return null;
	}

	/**
	 * Formats a metric value as text.
	 *
	 * @param value The metric value or a formatted string for a metric assessment.
	 * @static
	 */
	public static formatMetricAsText(
		value: number | string | boolean | null,
		schemaEntryOrMetricAssessment: SchemaLikeType,
		options: Record<string, unknown> | null = {}
	): string {
		if (value == null) {
			return '';
		}
		const formatterOptions = {
			[MetricFormatterBase.IS_RATIO_OPTION]: MetricProperties.isRatioMetric(schemaEntryOrMetricAssessment),
			[MetricFormatterBase.IS_DURATION_OPTION]: schemaEntryOrMetricAssessment.name === MetricsUtils.DURATION
		};
		if (options != null) {
			Object.assign(formatterOptions, options);
		}
		const valueType = MetricsUtils.getSchemaEntryOrMetricAssessmentValueType(schemaEntryOrMetricAssessment);
		const formatter = MetricFormatterFactory.createFormatterForMetric(valueType, formatterOptions);
		if (formatter != null) {
			return formatter.formatValueAsText(value);
		}
		return '';
	}

	/**
	 * Formats a threshold value as text.
	 *
	 * @param value The metric value or a formatted string for a metric assessment.
	 * @static
	 */
	public static formatThresholdText(value: number | string, schemaEntryOrMetricAssessment: SchemaLikeType): string {
		const valueType = MetricsUtils.getSchemaEntryOrMetricAssessmentValueType(schemaEntryOrMetricAssessment);
		if (valueType === EMetricValueType.ASSESSMENT.name) {
			// Formatting only a single numeric assessment threshold value as percentage.
			return NumericValueFormatter.formatAsPercentage(value as number);
		}
		return MetricsUtils.formatMetricAsText(value, schemaEntryOrMetricAssessment);
	}

	/** Returns the SchemaEntry or MetricAssessmentType */
	private static getSchemaEntryOrMetricAssessmentValueType(
		schemaEntryOrMetricAssessment: SchemaLikeType | string
	): string {
		if (typeof schemaEntryOrMetricAssessment === 'string') {
			return schemaEntryOrMetricAssessment;
		}
		return schemaEntryOrMetricAssessment.valueType;
	}

	/** Returns the index for a given metric or null if not found. */
	public static getMetricIndex(metricName: string, metricsSchema: MetricDirectorySchema): number | null {
		const index = metricsSchema.entries.findIndex(entry => entry.name === metricName);
		if (index === -1) {
			// No element found
			return null;
		}
		return index;
	}

	/**
	 * Returns the metric directory schema entry for a given metric or null if not found.
	 *
	 * @param metricsSchema To search through
	 * @static
	 */
	public static getMetricEntry(
		metricName: string,
		metricsSchema: MetricDirectorySchema
	): MetricDirectorySchemaEntry | null {
		const schemaEntries = metricsSchema.entries;
		for (const schemaEntry of schemaEntries) {
			if (schemaEntry.name === metricName) {
				return schemaEntry;
			}
		}
		return null;
	}

	/** Returns the names of all metrics. */
	public static getAllMetricNames(metricsSchema: MetricDirectorySchema): string[] {
		return metricsSchema.entries.map(entry => entry.name);
	}

	/**
	 * Returns a description for the metric defined in the given schema entry. The description is rendered to HTML using
	 * the 'Remarkable' markup renderer.
	 *
	 * @returns In HTML encoding
	 */
	public static getMetricDescription(schemaEntry: MetricDirectorySchemaEntry): string {
		const aggregation = (schemaEntry.aggregation as EAggregationEntry | null) || EAggregation.NONE.name;
		return MarkdownUtils.renderToPlainText(schemaEntry.description + ' (Aggregation: ' + aggregation + ')');
	}

	/**
	 * @returns The name of the first metric in the schema that is suitable as a size metric for treemaps, or an empty
	 *   string if no suitable metric.
	 */
	public static getDefaultSizeMetric(metricsSchema: MetricDirectorySchema | null | undefined): string {
		if (!metricsSchema) {
			return '';
		}
		let metric = MetricsUtils.findMetric(metricsSchema, schemaEntry => {
			// Prefer other size metrics over Files metric.
			return MetricProperties.isSizeMetric(schemaEntry) && schemaEntry.name !== EMetricName.FILES;
		});
		if (metric != null) {
			return metric.name;
		}
		metric = MetricsUtils.findMetric(metricsSchema, schemaEntry => schemaEntry.name === EMetricName.FILES);
		if (metric != null) {
			return metric.name;
		}
		return '';
	}

	/**
	 * Returns the first metric from metricsSchema for which the function <code>f</code> returns true, or
	 * <code>null</code> if no such metric can be found.
	 *
	 * @param selectorFunction A function that should return true if the given metric entry matches, false otherwise.
	 */
	public static findMetric(
		metricsSchema: MetricDirectorySchema,
		selectorFunction: (p1: MetricDirectorySchemaEntry) => boolean
	): MetricDirectorySchemaEntry | null {
		for (const entry of metricsSchema.entries) {
			if (selectorFunction(entry)) {
				return entry;
			}
		}
		return null;
	}

	/**
	 * Adds a leading '+' for positive numbers or changes nothing if the input is not a number-like format.
	 *
	 * @param text The text to be formatted.
	 * @returns The formatted text.
	 */
	public static addPlusIfNecessary(text: string): string {
		if (text.match(/^\d.*$/)) {
			return '+' + text;
		}
		return text;
	}

	/**
	 * Returns the location of a metric in a code or non-code schema.
	 *
	 * @param metricName Name of some metric.
	 * @returns A numeric location > -1 if found otherwise -1
	 */
	public static findMetricIndexInSchema(
		schema: MetricDirectorySchema | null | undefined,
		metricName: string
	): number {
		if (schema == null) {
			return -1;
		}
		return schema.entries.findIndex(entry => entry.name === metricName);
	}

	/**
	 * @param metricNames The metrics currently visible in the table
	 * @param schema
	 * @returns The value types of the metrics
	 */
	public static getValueTypesFromSchema(metricNames: string[], schema: MetricDirectorySchema): string[] {
		return metricNames.map(metricName => MetricsUtils.getMetricEntry(metricName, schema)!.valueType);
	}

	/**
	 * Formats the given metric delta of the given type be setting the name and formattedDiff fields of the metricDelta
	 * object.
	 */
	public static formatMetricDelta(
		metricDelta: ExtendedMetricDeltaValue | ExtendedAssessmentDelta,
		schemaEntry: MetricDirectorySchemaEntry
	): void {
		metricDelta.metricName = schemaEntry.name;
		if (!('diff' in metricDelta) || metricDelta.diff == null) {
			metricDelta.formattedDiff = MetricsUtils.NOT_AVAILABLE_STRING_LOWERCASE;
			metricDelta.formattedCurrentValue = MetricsUtils.NOT_AVAILABLE_STRING_LOWERCASE;
			return;
		}
		if (schemaEntry.valueType === EMetricValueType.ASSESSMENT.name) {
			metricDelta.formattedDiff = MetricsUtils.formatAssessmentDelta(metricDelta.diff as AssessmentDelta);
		} else {
			const numberValue = metricDelta.diff as number;
			if (!MetricsUtils.isRelevantDelta(numberValue, schemaEntry)) {
				metricDelta.formattedDiff = MetricsUtils.NO_CHANGE;
			} else if (MetricProperties.isRatioMetric(schemaEntry)) {
				const text = NumericValueFormatter.formatAsPercentage(numberValue);
				metricDelta.formattedDiff = MetricsUtils.addPlusIfNecessary(text);
			} else {
				metricDelta.formattedDiff = MetricsUtils.formatMetricAsHtml(numberValue, schemaEntry, {
					[MetricFormatterBase.ABBREVIATE_VALUES_OPTION]: false,
					[MetricFormatterBase.ALWAYS_SHOW_SIGN]: true
				});
				const compactText = NumericValueFormatter.formatDoubleMetricCompact(numberValue, schemaEntry);
				metricDelta.compactDiff = MetricsUtils.addPlusIfNecessary(compactText);
			}
		}
		metricDelta.formattedCurrentValue = MetricsUtils.formatAbsoluteValue(metricDelta, schemaEntry);
	}

	/**
	 * Formats the given string as absolute value.
	 *
	 * @param metricDelta
	 * @returns The formatted value
	 */
	private static formatAbsoluteValue(
		metricDelta: MetricDeltaValue | AssessmentDelta,
		schemaEntry: MetricDirectorySchemaEntry
	): string {
		if (schemaEntry.valueType === EMetricValueType.ASSESSMENT.name) {
			if ('currentvalues' in metricDelta) {
				return MetricsUtils.formatAssessmentValues(metricDelta.currentvalues, false);
			} else {
				return MetricsUtils.NOT_AVAILABLE_STRING_LOWERCASE;
			}
		}

		metricDelta = metricDelta as MetricDeltaValue;
		// The other types have have MetricDeltaValue (field currentvalue)
		if (metricDelta.currentvalue == null) {
			return MetricsUtils.NOT_AVAILABLE_STRING_LOWERCASE;
		}
		const absoluteValue = metricDelta.currentvalue;
		if (schemaEntry.valueType === EMetricValueType.TIMESTAMP.name) {
			// For timestamps, the absolute value is the same as the "delta" value
			return TimestampMetricFormatter.formatTimestampAsText(absoluteValue);
		}
		if (MetricProperties.isRatioMetric(schemaEntry)) {
			return NumericValueFormatter.formatAsPercentage(absoluteValue);
		} else {
			return NumericValueFormatter.formatDoubleMetricCompact(absoluteValue, schemaEntry);
		}
	}

	/** @returns Whether the delta is insignificantly small */
	public static isRelevantDelta(delta: number, schemaEntry: MetricDirectorySchemaEntry | MetricAssessment): boolean {
		const abs = Math.abs(delta);
		if (MetricProperties.isRatioMetric(schemaEntry)) {
			// We have two fraction digits and this is percent formatted, so we want
			// to display if there is a change in the last fraction digit
			return abs >= 0.0001;
		}
		return abs >= 0.1;
	}

	/**
	 * Formats an assessment delta object into a string.
	 *
	 * @param assessmentDelta The assessment delta to be formatted.
	 */
	public static formatAssessmentDelta(assessmentDelta: AssessmentDelta): string {
		const colorFrequencies = assessmentDelta.deltas;
		let text = '';
		let first = true;
		for (let j = 0; j < ETrafficLightColor.values.length; j++) {
			if (colorFrequencies[j] !== 0) {
				if (!first) {
					text += ', ';
				}
				first = false;
				const color = ETrafficLightColor.values[j]!.assessmentDisplayName.toLowerCase();
				text += MetricsUtils.addPlusIfNecessary(colorFrequencies[j]!.toString()) + ' ' + color;
			}
		}
		if (first) {
			text = MetricsUtils.NO_CHANGE;
		}
		return text;
	}

	/**
	 * Formats an assessment values into a string (used e.g., as tooltips on delta metric tables).
	 *
	 * @param colorFrequencies Assessment values(normally RED, ORANGE, YELLOW, GREEN, ...). Corresponding to
	 *   ETrafficLightColor.
	 * @param assumeDelta Handle as assessment delta (adds a '+' before each value)
	 */
	public static formatAssessmentValues(colorFrequencies: number[], assumeDelta: boolean): string {
		let text = '';
		let first = true;
		for (let j = 0; j < ETrafficLightColor.values.length; j++) {
			if (colorFrequencies[j] === 0) {
				continue;
			}
			if (!first) {
				text += ', ';
			}
			first = false;
			const color = ETrafficLightColor.values[j]!.name.toLowerCase();
			if (assumeDelta) {
				text += MetricsUtils.addPlusIfNecessary(colorFrequencies[j]!.toString()) + ' ' + color;
			} else {
				text += colorFrequencies[j]!.toString() + ' ' + color;
			}
		}
		if (first) {
			text = MetricsUtils.NO_CHANGE;
		}
		return text;
	}

	/**
	 * @returns The threshold profile from the navigation hash iff it is contained in the list of all profiles.
	 *   Otherwise, returns Teamscale's default configuration.
	 */
	public static getActiveThresholdProfile(allThresholdProfiles: string[]): string {
		return (
			MetricsUtils.getExistingThresholdFromUrl(allThresholdProfiles) ||
			MetricsUtils.TEAMSCALE_THRESHOLD_CONFIGURATION
		);
	}

	/** @returns If none is set or the profile does not exist. */
	public static getExistingThresholdFromUrl(allThresholdProfiles: string[]): string | null {
		const thresholdProfileInUrl = NavigationHash.getCurrent().getThresholdProfile();
		if (thresholdProfileInUrl !== null && allThresholdProfiles.includes(thresholdProfileInUrl)) {
			return thresholdProfileInUrl;
		}
		return null;
	}

	/** The key for the local storage containing whether numeric values should be rounded. */
	public static getNumberFormatStorageKey(visibilitySupportName: string): string {
		return 'round-numeric-in-metrics-table-' + visibilitySupportName;
	}
}
